import styled from '@emotion/styled'
import { Button } from 'app/components/Common/Button'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { Media } from 'app/theme/media'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo, useRef } from 'react'
import { isMobile } from 'react-device-detect'
import Fade from 'react-reveal/Fade'

import { MenuItem, Props as MenuItemProps } from './MenuItem'

export interface Props {
  address?: string
  backgroundImage?: { [key: string]: any }
  country?: string
  email?: string
  fax?: string
  IBEID?: number
  languageCode: string
  languagePrefix: string | null
  logo?: string
  locality?: string
  menuItems: MenuItemProps[]
  navigationStatus?: boolean
  phone?: string
  postalCode?: string
  region?: string
  siteName?: string
  whatsapp?: string
}

export const Navigation = memo(function Navigation({
  address,
  backgroundImage,
  country,
  email,
  fax,
  IBEID,
  languageCode,
  languagePrefix,
  logo,
  locality,
  menuItems,
  navigationStatus,
  phone,
  postalCode,
  region,
  siteName,
  whatsapp,
}: Props) {
  const contactsRef = useRef(null)

  return (
    <Container row>
      <LeftSide>
        {menuItems ? (
          <Nav>
            <Fade left cascade duration={600} when={navigationStatus}>
              <NavList>
                {menuItems.map((item, index) => (
                  <NavLi key={index}>
                    <MenuItem {...item} />
                  </NavLi>
                ))}
                <Media lessThan="desktopSmall">
                  <NavLi>
                    <MenuItem
                      url={`https://be.bookingexpert.it/book/simple/step1?hotel=${IBEID}&lang=${
                        languagePrefix ? languagePrefix : 'en'
                      }`}
                      title={useVocabularyData('book', languageCode)}
                    />
                  </NavLi>
                </Media>
              </NavList>
            </Fade>
          </Nav>
        ) : null}
        <LeftBottom
          className={navigationStatus ? 'visible' : undefined}
          dial={7}
          ref={contactsRef}
          row
          space="between"
        >
          <Contacts>
            {address ? (
              <Address>{`${address} - ${postalCode} ${locality} ${region} ${country}`}</Address>
            ) : null}
            {phone ? (
              <ContactsItem>
                <Anchor
                  href={`tel:${phone}`}
                  onClick={() => {
                    if (isMobile) {
                      typeof window.gtag !== 'undefined' &&
                        window.gtag('event', 'Click', {
                          event_category: 'Website',
                          event_label: 'Mobile Phone',
                        })
                    }
                  }}
                >
                  {`${useVocabularyData('ph', languageCode)}: ${phone}`}
                </Anchor>
              </ContactsItem>
            ) : null}
            {whatsapp ? (
              <ContactsItem>
                <Anchor
                  href={`https://api.whatsapp.com/send?phone=${whatsapp}`}
                >
                  {`${useVocabularyData(
                    'whatsapp',
                    languageCode,
                  )}: ${whatsapp}`}
                </Anchor>
              </ContactsItem>
            ) : null}
            {fax ? (
              <ContactsItem>
                <Fax>{`${useVocabularyData('fax', languageCode)}: ${fax}`}</Fax>
              </ContactsItem>
            ) : null}
            {email ? (
              <Email
                href={`mailto:${email}`}
                onClick={() => {
                  typeof window.gtag !== 'undefined' &&
                    window.gtag('event', 'Click', {
                      event_category: 'Website',
                      event_label: 'Email Address',
                    })
                }}
              >
                {`${useVocabularyData('email', languageCode)}: ${email}`}
              </Email>
            ) : null}
          </Contacts>
          {IBEID ? (
            <CTA
              arrow
              className={navigationStatus ? 'visible' : undefined}
              URL={`https://be.bookingexpert.it/book/simple/step1?hotel=${IBEID}&lang=${
                languagePrefix ? languagePrefix : 'en'
              }`}
              label={useVocabularyData('book', languageCode)}
              rel="noopener"
              target="_blank"
            />
          ) : null}
        </LeftBottom>
      </LeftSide>
      <RightSide dial={5}>
        {backgroundImage ? (
          <BackgroundImage
            src={backgroundImage.src}
            srcSet={backgroundImage.srcSet}
            width={backgroundImage.width}
            height={backgroundImage.height}
            alt={siteName}
          />
        ) : null}
        {logo ? (
          <Logo src={logo} alt={siteName} width="151" height="120" />
        ) : null}
      </RightSide>
    </Container>
  )
})

const Container = styled(FlexBox)`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;

  @media (max-width: 767px) {
    display: block;
    overflow-y: auto;
    -webkit-overflow-scrolling: auto;
  }
`

const LeftSide = styled(FlexBox)`
  width: 60%;
  height: 100%;
  padding: 6vh 5vw 9vh;
  position: relative;

  @media (max-width: 1023px) {
    width: 100%;
  }

  @media (max-width: 767px) {
    padding: 77px 22px 0;
  }
`

const Nav = styled.nav`
  @media (max-width: 767px) {
    width: 100%;
    padding-bottom: 60px;
  }
`

const NavList = styled.ul`
  height: 70vh;
  display: flex;
  flex-flow: column wrap;

  @media (max-width: 767px) {
    display: block;
    height: auto;
  }
`

const NavLi = styled.li`
  width: 22.917vw;
  margin-bottom: 1.8vh;

  @media (max-width: 1023px) {
    width: 40vw;
  }

  @media (max-width: 767px) {
    width: auto;
    border-bottom: 1px solid
      ${({ theme }) => theme.colors.variants.neutralLight0};
    margin-bottom: 12px;
    padding-bottom: 12px;
  }
`

const LeftBottom = styled(FlexBox)`
  width: calc(100% - 16.042vw);
  position: absolute;
  bottom: 9.3vh;
  left: 5vw;
  opacity: 0;
  transform: translateY(3.75rem);
  transition: 0.4s ease-out;
  &.visible {
    opacity: 1;
    transform: translateY(0);
    transition-delay: 0.6s;
  }

  @media (max-width: 1023px) {
    width: calc(100% - 10vw);
  }

  @media (max-width: 767px) {
    display: block;
    width: 100%;
    margin-top: 3.75rem;
    position: relative;
    bottom: auto;
    left: auto;
  }
`

const Contacts = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.75rem;
  line-height: 1.375rem;

  @media (max-width: 767px) {
    display: none;
  }
`

const ContactsItem = styled.div``

const CTA = styled(Button)`
  margin-top: 0;

  @media (max-width: 1023px) {
    margin-top: 0;
  }

  @media (max-width: 767px) {
    display: none;
  }
`

const Address = styled.div``

const Anchor = styled.a`
  &:hover {
    text-decoration: underline;
  }
`

const Fax = styled.div``

const Email = styled.a`
  &:hover {
    text-decoration: underline;
  }
`

const RightSide = styled(FlexBox)`
  width: 40%;
  height: 100%;
  position: relative;
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralDark1};
    opacity: 0.5;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }

  @media (max-width: 1023px) {
    display: none;
  }
`

const BackgroundImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
`

const Logo = styled.img`
  width: auto;
  margin: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
`
